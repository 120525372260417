<template>
  <div class="not-found">
    <v-col>
      <h1>404 NOT FOUND.</h1>
    </v-col>
  </div>
</template>
<style scoped lang="scss">

</style>
<script>
export default {
  data() {
    return {};
  },
};
</script>
